import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {SharedModule} from "./shared/shared.module";
import {HelperService} from "./shared/utils/helper.service";
import {HTTP_INTERCEPTORS, provideHttpClient} from "@angular/common/http";
import {HttpInterceptorService} from "./shared/authentication/http-interceptor.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DBConfig, NgxIndexedDBModule} from "ngx-indexed-db";
import Lottie from "lottie-web";
import {provideLottieOptions} from "ngx-lottie";
import {BASE_PATH} from "../../sdk/mynimc-api-sdk";
import {environment} from "../environments/environment";

export function lottiePlayerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

const dbConfig: DBConfig = {
  name: 'mynimcdb',
  version: 1,
  objectStoresMeta: [{
    store: 'mynimcuser',
    storeConfig: {keyPath: 'id', autoIncrement: true},
    storeSchema: [
      {name: 'userId', keypath: 'userId', options: {unique: true}},
      {name: 'crc', keypath: 'crc', options: {unique: true}},
      {name: 'token', keypath: 'token', options: {unique: false}}
    ]
  }]
};


export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: BASE_PATH,
      useValue: environment.siteUrl
    },
    provideHttpClient(),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideLottieOptions({
      player: lottiePlayerFactory,
    }),
    importProvidersFrom([BrowserAnimationsModule,
      SharedModule, NgxIndexedDBModule.forRoot(dbConfig)])

  ]
};
