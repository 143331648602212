import { Component } from '@angular/core';
import {SharedModule} from "../../../shared/shared.module";

@Component({
  selector: 'forbidden',
  standalone: true,
  templateUrl: './forbidden.component.html',
  imports: [
    SharedModule,

  ],
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent {}
