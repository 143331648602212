import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {TokenizationPojo} from "../../../../../../sdk/mynimc-api-sdk";
import {NgIf, TitleCasePipe, UpperCasePipe} from "@angular/common";
import {QRCodeModule} from "angularx-qrcode";
import {CdkCopyToClipboard, Clipboard} from "@angular/cdk/clipboard";
import {AlertType} from "../../../../shared/utils/utils";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-generate-token-iii',
  standalone: true,
  imports: [
    TitleCasePipe,
    UpperCasePipe,
    QRCodeModule,
    CdkCopyToClipboard,
    NgIf,
    RouterLink
  ],
  templateUrl: './generate-token-iii.component.html',
  styleUrl: './generate-token-iii.component.css',
  schemas:[ CUSTOM_ELEMENTS_SCHEMA ]
})
export class GenerateTokenIiiComponent implements OnInit, OnChanges{
  @Output() emitBackButtonClicked = new EventEmitter<any>();
  @Input() tokenizationPojo!: TokenizationPojo;
  @Output() alert = new EventEmitter<{ msg: string; type: AlertType }>();

  constructor(private clipboard: Clipboard) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tokenizationPojo'] && changes['tokenizationPojo'].currentValue) {
      this.tokenizationPojo = changes['tokenizationPojo'].currentValue;
    }
  }

  copyToClipBoard(){
    const copied = this.clipboard.copy(this.tokenizationPojo?.token);
    if(copied)
      this.alert.emit({msg: 'Token copied successfully', type: AlertType.success});
  }

}
