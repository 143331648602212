import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'fullDateTime'
})
export class FullDateTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    const m = moment(value);
    return m.isValid() ? m.format('ddd, MMM Do YYYY, h:mmA') : null; // 01 Mar 2019, 10:48AM
  }

}
