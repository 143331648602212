<div class="container">
  <div class="mt-5 ">
    <div class="d-flex align-items-center pointer">
      <div class="p-1 px-2 me-2" style="background: rgba(1, 135, 58, 0.50); border-radius: 4px">
        <span class="iconify" data-icon="ep:back"></span>
      </div>
      <p class="mb-0 fs-14">Go back</p>
    </div>

    <div class="mt-3" style="font-size: 45px;">
      Generating a Token
    </div>
    <div>
      <div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert alert-danger my-5 text-center" role="alert"
      >
        {{ getAlertMessage() }}
      </div>
      <mat-stepper linear #stepper [selectedIndex]="0" class="custom-stepper">
        <ng-template matStepperIcon="number" let-index="index">
          <span></span>
        </ng-template>
        <mat-step>
          <ng-template matStepLabel>
            <div class="d-flex flex-column align-items-center">
              <mat-icon inline>
                <span [ngClass]="{'active': myStepper?.selectedIndex == 0}"
                  class="iconify custom-icon" data-icon="mingcute:sector-line"></span>
              </mat-icon>
              <div [ngStyle]="myStepper?.selectedIndex == 0 ? {'color': '#01903C'} : {'color': 'black'}">Sector</div>
            </div>
          </ng-template>
          <app-generate-token-i
            (emitFirstBackButtonClicked)="onFirstBackButton($event)"
            (alert)="handleMessages($event)"
            (onFirstFormNextClicked)="handleNextClicked($event, stepper)">
          </app-generate-token-i>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>
            <div class="d-flex flex-column align-items-center">
              <mat-icon inline>
                <span [ngClass]="{'active': myStepper?.selectedIndex == 1}"
                  class="iconify custom-icon" data-icon="mdi:partnership-outline"></span>
              </mat-icon>
              <div [ngStyle]="myStepper?.selectedIndex == 1 ? {'color': '#01903C'} : {'color': 'black'}"> Relying Party</div>
            </div>
          </ng-template>
          <app-generate-token-ii (emitBackButtonClicked)="handleBackButtonClick(stepper)">
          </app-generate-token-ii>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>
            <div class="d-flex flex-column align-items-center">
              <mat-icon inline>
                <span
                  class="iconify custom-icon" [ngClass]="{'active': myStepper?.selectedIndex == 2}" data-icon="solar:password-linear"></span>
              </mat-icon>
              <div [ngStyle]="myStepper?.selectedIndex == 2 ? {'color': '#01903C'} : {'color': 'black'}">Generated Token</div>
            </div>
          </ng-template>
          <app-generate-token-iii
            [tokenizationPojo]="this.generatedTokenPojo"
            (emitBackButtonClicked)="handleBackButtonClick(stepper)">
          </app-generate-token-iii>
        </mat-step>
      </mat-stepper>

    </div>
  </div>
</div>
