import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent {

  constructor(private router: Router) {
  }

  goBack(){
    this.router.navigate(['/verify-crc']);
  }

  generateToken(){
    this.router.navigate(['/generate-token']);
  }

  viewToken(){
    this.router.navigate(['/view-token-history']);
  }
}
