
export class User {
  ninHash?: string;
  firstName?: string;
  lastName?: string;

  constructor(data: any) {
    //@ts-ignore
    Object.assign(this, data);
  }
}
