import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertType} from "../../../shared/utils/utils";
import {MatStep, MatStepLabel, MatStepper, MatStepperIcon} from "@angular/material/stepper";
import {GenerateTokenIComponent} from "./generate-token-i/generate-token-i.component";
import {GenerateTokenIiiComponent} from "./generate-token-iii/generate-token-iii.component";
import {GenerateTokenIiComponent} from "./generate-token-ii/generate-token-ii.component";
import {Router} from "@angular/router";
import {
  GeneratedTokenControllerService, TokenizationPojo,
  UserGenerateTokenDto
} from "../../../../../sdk/mynimc-api-sdk";
import {HelperService} from "../../../shared/utils/helper.service";
import {MatIcon} from "@angular/material/icon";
import {NgClass, NgIf, NgStyle} from "@angular/common";

@Component({
  selector: 'app-generate-token',
  standalone: true,
  imports: [
    GenerateTokenIComponent,
    MatStep,
    MatStepper,
    GenerateTokenIiiComponent,
    GenerateTokenIiComponent,
    MatIcon,
    MatStepLabel,
    MatStepperIcon,
    NgClass,
    NgStyle,
    NgIf
  ],
  templateUrl: './generate-token.component.html',
  styleUrl: './generate-token.component.css'
})
export class GenerateTokenComponent implements OnInit {
  @ViewChild('stepper') public myStepper: MatStepper;
  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  };
  showAlertMessageTrigger = false;
  sectorCode: string;
  customerAccountCode: string;
  submittingData: boolean;
  generatedTokenPojo: TokenizationPojo;

  constructor(private router: Router,
              private helperService: HelperService,
              private generatedTokenControllerService: GeneratedTokenControllerService) {
  }

  ngOnInit(): void {
  }

  onFirstBackButton($event: any) {
    this.router.navigate(['/dashboard']);
  }

  handleMessages($event: { msg: string; type: AlertType }) {
    this.showAlertMessage($event.msg, $event.type);
  }

  handleBackButtonClick(stepper: MatStepper): void {
    stepper.previous();
  }

  handleNextClicked($event: any, stepper: MatStepper) {
    const stepNumber = stepper.selectedIndex;
    if ($event) {
      if (stepNumber == 0) {
        this.sectorCode = $event;
        stepper.next();
      } else if (stepNumber == 1) {
        this.customerAccountCode = $event;
        if (this.customerAccountCode) {
          this.submitData(stepper);
        } else {
          this.showAlertMessage('relying party account code not selected.', AlertType.error);
        }
      } else {
        this.showAlertMessage('invalid step number', AlertType.error);
      }

    }
  }


  submitData(stepper: MatStepper) {
    this.submittingData = true;

    const userGenerateDto: UserGenerateTokenDto = {
      crcCode: '',
      customerAccountCode: this.customerAccountCode
    }

    //TODO://Update consent code
    this.generatedTokenControllerService.generateAndGetToken({dto: userGenerateDto}).subscribe({
      next: (v) => {
        this.submittingData = false;
        stepper.next();
        this.generatedTokenPojo = v;
      }, error: (err: unknown) => {
        this.submittingData = false;
        this.showAlertMessage(this.helperService.getError(err), AlertType.error);
      }
    })
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  protected readonly AlertType = AlertType;
}
