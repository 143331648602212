import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {AsyncSubject, firstValueFrom, from, Observable, Observer, pipe} from 'rxjs';
import {AuthenticationService} from './authentication-service.service';
import {KeycloakService} from 'keycloak-angular';
import {mergeMap} from 'rxjs/operators';
import moment from 'moment';
import {environment} from "../../../environments/environment";
import {DatabaseService} from "../utils/database.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private static TOKEN_NAME = 'TOKEN';
  private static headers: any = {};
  private _httpError: EventEmitter<HttpErrorResponse> = new EventEmitter();
  private _lastSeen!: moment.Moment;
  private accountCode: any;

  constructor(
    private keycloakService: KeycloakService,
    private databaseService: DatabaseService,
    private authenticationService: AuthenticationService) {
  }
  public get httpError() {
    return this._httpError;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpHeaders = {} as any;
    const handled: Observable<HttpEvent<any>> = this.databaseService.getAllUser()
      .pipe(mergeMap(userPojo => {
        if (userPojo) {
          httpHeaders['Authorization'] = 'bearer ' + userPojo[0].token;
        }
        if(this.accountCode){
          httpHeaders['X-ACCOUNT-CODE'] = this.accountCode;
        }
        httpHeaders['X-USER-AGENT'] = window.navigator.userAgent;
        httpHeaders['X-SYSTEM-NAME'] = 'mynimc'
        const  headers= new HttpHeaders();
        headers.set('Authorization', `Bearer ${ userPojo[0].token}`)
        this.keycloakService.addTokenToHeader(headers);

         // if(this.keycloakService.)
        return next.handle(req.clone({setHeaders: httpHeaders}));
      }));

    const subject: AsyncSubject<HttpEvent<any>> = new AsyncSubject();
    handled.subscribe(subject);


    subject.subscribe(async (event: HttpEvent<any>) => {

      if (event instanceof HttpErrorResponse) {

        this._httpError.emit(event);
      }
    }, async (err: HttpEvent<any>) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status < 1) {

        } else if (err.status === 401) {

          return;
        } else if (err.status === 404) {
          return;
        } else if (err.status === 403) {
          this.authenticationService.forbidAccess();
          return;
        }
        this._httpError.emit(err);
      }
    });
    return Observable.create((obs: Observer<HttpEvent<any>>) => {
      subject.subscribe(obs);
    });
  }

}
