/**
 * Util API
 * The Util RESTFul service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: judoh@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GeneratedTokenSearchFilter { 
    offset?: number;
    limit?: number;
    order?: GeneratedTokenSearchFilter.OrderEnum;
    orderColumn?: string;
    priorityItems?: Array<number>;
    templateType?: GeneratedTokenSearchFilter.TemplateTypeEnum;
    keyword?: string;
}
export namespace GeneratedTokenSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type TemplateTypeEnum = 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    export const TemplateTypeEnum = {
        Normal: 'NORMAL' as TemplateTypeEnum,
        DropdownData: 'DROPDOWN_DATA' as TemplateTypeEnum,
        Lean: 'LEAN' as TemplateTypeEnum
    };
}


