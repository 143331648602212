import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeOnly'
})
export class TimeOnlyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value){
      return value;
    }
    const m = moment(value);
    return m.isValid() ? m.format('hh:mmA') : null;//10:48AM
  }

}
