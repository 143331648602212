<div class="container">
  <div class="mt-5">
    <div class="d-flex align-items-center pointer" (click)="goBack()">
      <div class="p-1 px-2 me-2" style="background: rgba(1, 135, 58, 0.50); border-radius: 4px">
        <span class="iconify" data-icon="ep:back"></span>
      </div>
      <p class="mb-0 fs-14">Go back</p>
    </div>


    <div class="mt-5" style="font-size: 45px;">
      Token Management
    </div>

    <div class=" mt-5 mx-auto px-3 px-md-0 col-md-6 col-12" >
      <div class="card rounded-4 border-0 " style="box-shadow: 0px 1px 15px 0px rgba(0, 103, 143, 0.15);" >
        <div class="card-body">
          <div class="row g-3">
            <div class="col-md-6 col-12">
              <div class="card rounded-4 pointer" (click)="generateToken()" style="background: rgba(1, 144, 60, 0.05); border: 1px solid rgba(1, 144, 60, 0.40);">
                <div class="card-body py-5">
                  <div class=" text-center d-flex flex-column align-items-center justify-content-center ">
                    <div style="border-radius: 7px;background: rgba(1, 135, 58, 0.16); width: 41px; height: 37px; padding: 5px 7px; display: flex; align-items: center; justify-content: center;">
                      <span class="iconify fs-2" data-icon="material-symbols-light:token-outline"></span>
                    </div>
                    <div class="mt-3" style="font-weight: bold; font-size: 22px; text-align: center;">

                      Generate a Token
                    </div>
                    <div class="mt-3">
                      <span class="iconify fs-4" data-icon="ph:arrow-right-fill"></span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="card rounded-4 pointer h-100" (click)="viewToken()" style="background: rgba(0, 82, 204, 0.05); border: 1px solid rgba(0, 82, 204, 0.40);">
                <div class="card-body py-5">
                  <div class=" text-center d-flex flex-column align-items-center justify-content-center ">
                    <div style="border-radius: 7px; background: rgba(0, 82, 204, 0.16); width: 41px; height: 37px; padding: 5px 7px; display: flex; align-items: center; justify-content: center;">
                      <span class="iconify fs-2" data-icon="lucide:view"></span>
                    </div>
                    <div class="mt-3" style="font-weight: bold; font-size: 22px; text-align: center;">
                      View My Tokens
                    </div>
                    <div class="mt-3">
                      <span class="iconify fs-4" data-icon="ph:arrow-right-fill"></span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>

      </div>
    </div>

  </div>
</div>

