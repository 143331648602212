import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';


@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(!value){
      return value;
    }
    const m = moment(value);
    return m.isValid() ? m.format('DD MMM YYYY') : null;//01 Mar 2019
  }

}
