<div class="container">
  <div class="mt-5 ">
    <div class="">
      <div>

      </div>
      <div class="mt-3" style="font-size: 32px;">
        Welcome To
      </div>
      <div class="fs-34 mt-1" style="font-weight: bolder; font-size: 50px">
        My NIMC Mobile
      </div>

      <div class="col-md-3">
        <div class="card rounded-5 border-0" style="font-size: 14px">
          <div class="card-body">
            Manage your tokens securely and easily
          </div>
        </div>
      </div>

      <div class="my-3 ">
        <button class="btn btn-success rounded-3" [routerLink]="'/verify-crc'">

          Proceed
          <span class="iconify fs-3" data-icon="basil:arrow-right-outline"></span>
        </button>
      </div>
    </div>

    <div class="mx-auto mt-5 justify-content-center text-center col-md-3" style="font-weight: bold; font-size: 18px;">
      <div class="card rounded-5 "
           style=" border-top: 1px solid #01873A; border-bottom: 1px solid #01873A; border-right: none; border-left: none  ">
        <div class="py-2">
          What You Can Do
        </div>
      </div>
    </div>

    <div class="text-center">
      <div class="mt-5 ">
        <div class="row col-gap-3 row-gap-3 ">
          <div class="col-md-4 text-center align-items-center justify-content-center d-flex flex-column ">
            <div
              style="border-radius: 7px; background: rgba(1, 135, 58, 0.20); width: 41px; height: 37px; padding: 5px 7px;">
              <i class="iconify icon" data-icon="material-symbols-light:token-outline" data-width="25px"></i>
            </div>
            <div class="mt-3" style="font-weight: bold; font-size: 22px;">
              Generate a Token
            </div>
            <div>
              A token is a unique code used for secure authentication or transactions.
            </div>
          </div>
          <div class="col-md-4 text-center align-items-center justify-content-center d-flex flex-column ">
            <div
              style="border-radius: 7px; background: rgba(0, 82, 204, 0.20); width: 41px; height: 37px; padding: 5px 7px;">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 15V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V15M19 5V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.944 10.33C17.0187 10.1164 17.0187 9.88369 16.944 9.67003C16.381 8.29045 15.4198 7.10985 14.183 6.27879C12.9463 5.44774 11.49 5.00391 9.99999 5.00391C8.50995 5.00391 7.05369 5.44774 5.81693 6.27879C4.58018 7.10985 3.619 8.29045 3.05599 9.67003C2.9813 9.88369 2.9813 10.1164 3.05599 10.33C3.619 11.7096 4.58018 12.8902 5.81693 13.7213C7.05369 14.5523 8.50995 14.9962 9.99999 14.9962C11.49 14.9962 12.9463 14.5523 14.183 13.7213C15.4198 12.8902 16.381 11.7096 16.944 10.33Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="mt-3" style="font-weight: bold; font-size: 22px;">
              View My Tokens
            </div>
            <div>
              Viewing tokens allows you to see all currently active or previously generated tokens
            </div>
          </div>

          <div class="col-md-4 text-center d-flex flex-column align-items-center justify-content-center">
            <div
              style="border-radius: 7px; background: rgba(221, 54, 41, 0.20); width: 41px; height: 37px; padding: 5px 7px; align-items: center; justify-content: center;">
              <i class="iconify icon" data-icon="fluent:flash-off-24-regular" data-width="25px"></i>
            </div>
            <div style="font-weight: bold; font-size: 22px; text-align: center;">
              Deactivate a Token
            </div>
            <div style="text-align: center;">
              Deactivating a token makes it invalid for future use.
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="text-center my-5">
      <button class="btn btn-success py-2 px-3" [routerLink]="'/verify-crc'">
        <span class="iconify fs-3" data-icon="basil:arrow-right-outline"></span>
        Get Started
      </button>
    </div>
  </div>
</div>
