import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedModule} from "../../../shared/shared.module";
import {ConsentRegistrationControllerService, ValidateCRCDto} from "../../../../../sdk/mynimc-api-sdk";
import {AlertType} from "../../../shared/utils/utils";
import {HelperService} from "../../../shared/utils/helper.service";
import {RecaptchaModule} from "ng-recaptcha";
import {Constant} from "../../../models/etc/constant";
import {DatabaseService} from "../../../shared/utils/database.service";


@Component({
  selector: 'app-verify-crc-nin',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    SharedModule,
    RecaptchaModule
  ],
  templateUrl: './verify-crc-nin.component.html',
  styleUrl: './verify-crc-nin.component.css'
})
export class VerifyCrcNinComponent implements OnInit {
  form: FormGroup;
  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  };
  showAlertMessageTrigger = false;
  submitting: boolean;
  @ViewChild("recaptcha") recaptcha: ElementRef | undefined;
  recaptchaToken: string | undefined
  recaptchaTouched = false;

  constructor(private fb: FormBuilder,
              private helperService: HelperService,
              private router: Router,
              private dataService: DatabaseService,
              private consentRegistrationControllerService: ConsentRegistrationControllerService) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      crcCode: ['', [Validators.required]]
    })
  }


  validateCRC() {
    if (this.form.invalid) {
      window.scroll(0, 0)
      this.form.markAllAsTouched();
      return;
    }
    if (this.isRecaptchaInvalid || !this.recaptchaToken) {
      this.showAlertMessage("Please check the recaptcha box", AlertType.error);
      return
    }


    const form = this.form.getRawValue();

    const validateCRCDto: ValidateCRCDto = {
      crcCode: form.crcCode,
      nin: ''
    }

    this.consentRegistrationControllerService
      .validateCRC({validateCRCDto: validateCRCDto}).subscribe({
      next: (v) => {
        this.persistDatabaseRecord(v.userId, form.crcCode, v.jwt);
      }, error: (err: unknown) => {
        this.submitting = false;
        this.showAlertMessage(this.helperService.getError(err), AlertType.error)
      }
    });
  }

  persistDatabaseRecord(userId: string, crc: string, token: string){
    const person = { userId: userId, crc: crc, token: token};
    this.dataService.addUser(person).subscribe((id) => {
      this.router.navigate(['/dashboard']);
    });
  }


  resolved(captchaResponse: string) {

    this.recaptchaToken = captchaResponse;
    this.recaptchaTouched = true;
  }

  get isRecaptchaInvalid() {
    return (!this.recaptchaToken && this.recaptchaTouched);
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }
}
