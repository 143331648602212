<div class="container">
  <div class="mt-5 ">
    <div class="d-flex align-items-center pointer" [routerLink]="'/'">
      <div class="p-1 px-2 me-2" style="background: rgba(1, 135, 58, 0.50); border-radius: 4px">
        <span class="iconify" data-icon="ep:back"></span>
      </div>
      <p class="mb-0 fs-14">Go back</p>
    </div>

    <div class=" text-center mt-5">
      <div>

      </div>
    </div>
    <div class="mx-auto mt-5 justify-content-center px-3 px-md-0  col-md-4">
      <div class="card rounded-4  border-0 " style="box-shadow: 0px 1px 15px 0px rgba(0, 103, 143, 0.15);">
        <form [formGroup]="form" class="card-body">
          <div style="font-weight: bold; font-size: 18px;" class="">
            Enter your Consent Registration Code
          </div>

          <div class="mb-3 mt-3">
            <label for="crcCode" class="form-label">
              Consent Registration Code (CRC)
            </label>
            <input type="text" id="crcCode" class="form-control rounded-3" placeholder="Enter Consent Registration Code"
                   formControlName="crcCode"
            />
            <error-message [controlName]="'crcCode'" [singularError]="true" [form]="form" [label]="'crc'"></error-message>
          </div>
          <br>
          <div class="col-12">
            <re-captcha class="w-100" (resolved)="resolved($event)" siteKey="6LcTwMEoAAAAAEjtYDnHQuZD-a0n1tJrJsh9FlAC"></re-captcha>
          </div>
          <div class="my-4 me-3">
            <button class="btn btn-success rounded-3 col-12" (click)="validateCRC()">
              Proceed
              <span class="iconify fs-3" data-icon="basil:arrow-right-outline"></span>
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
