import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  WritableSignal
} from '@angular/core';
import {AlertType} from "../../../../shared/utils/utils";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CustomerControllerService, NameAndIdPojo, SectorControllerService} from "../../../../../../sdk/mynimc-api-sdk";
import {HelperService} from "../../../../shared/utils/helper.service";
import {NgForOf, NgIf} from "@angular/common";
import {SharedModule} from "../../../../shared/shared.module";

@Component({
  selector: 'app-generate-token-ii',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    SharedModule
  ],
  templateUrl: './generate-token-ii.component.html',
  styleUrl: './generate-token-ii.component.css'
})
export class GenerateTokenIiComponent implements OnInit, OnChanges{
  @Output()
  emitBackButtonClicked = new EventEmitter<any>();
  @Output()
  onNextClicked = new EventEmitter<any>();
  @Output() alert = new EventEmitter<{ msg: string; type: AlertType }>();
  form: FormGroup;
  relyingPartyList:WritableSignal<NameAndIdPojo[]> = signal([]);
  @Input()
  sectorCode: string;


  constructor(private customerControllerService: CustomerControllerService,
              private helperService: HelperService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      keyword: [''],
      customerAccountCode: ['', [Validators.requiredTrue]]
    })

    this.getRelyingParty();

    this.form.get('keyword').valueChanges.subscribe(res => {
      if (res != null) {
        this.getRelyingParty(res);
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['sectorCode'] && changes['sectorCode'].currentValue) {
           this.sectorCode = changes['sectorCode'].currentValue;
      }
  }

  getRelyingParty(keyword?: string) {
    this.customerControllerService.searchCustomer({sectorCode: this.sectorCode, keyword: keyword, limit: 5}).subscribe({
      next: (x) => {
        this.relyingPartyList.set(x);
      }, error: (err: unknown) => {
        this.alert.emit({msg: this.helperService.getError(err), type: AlertType.error});
      }
    })
  }

  selectNextClicked(){
    if(this.form.invalid){
      this.form.markAllAsTouched();
      this.alert.emit({msg: 'Kindly select a relying party before proceeding', type: AlertType.error});
    }
    this.onNextClicked.next(this.form.value.customerAccountCode)
  }


}
