import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {AsyncSubject, from, mergeMap, Observable, of, Subject, tap} from "rxjs";
import {KeycloakLoginOptions} from "keycloak-js";
import {environment} from "../../../environments/environment";
import {UserSessionManager} from "./user-session-manager";
import {catchError} from "rxjs/operators";
import {Constant} from "../models/constants";
import {PageManager} from "../misc/page-manager";
import {UserControllerService, UserPojo} from "../../../../sdk/mynimc-api-sdk";
import {User} from "../models/user/user.model";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private static user: Subject<UserPojo | null> = new Subject();
  public static _user: UserPojo;
  private static ongoingFetch: Observable<any> | null;
  private static initialized: boolean;
  private static cloneStateInitialized: boolean;

  private static newUserToken: EventEmitter<string | null> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private keycloak: KeycloakService,
    private router: Router,
    private userSessionManager: UserSessionManager,
    private pageManager: PageManager,
    private userControllerService: UserControllerService
  ) {
    AuthenticationService.user.subscribe((user: any) => {
      if (user === undefined) {
        return;
      }
      AuthenticationService.initialized = true;
      AuthenticationService._user = user;
    });
  }

  public clearStaleSession(): void {
    const redirect = AuthenticationService._user;
    AuthenticationService.user.next(undefined);
    localStorage.clear();
    sessionStorage.clear();
    this.pageManager.clearAllData();
    if (redirect) {
      location.href = this.router.createUrlTree(['/']).toString();
    }
  }


  public login(loginOptions: KeycloakLoginOptions) {
    return this.keycloak.login(loginOptions);
  }

  public getLoginUrl(loginOptions: KeycloakLoginOptions) {
    return this.keycloak.getKeycloakInstance().createLoginUrl(loginOptions);
  }

  public requestPasswordReset(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/password/forgot`, data);
  }

  public getUser() {
    return AuthenticationService.user;
  }

  public forbidAccess() {
    this.router.navigate(['/forbidden']);
  }

  public fetchUser(): Observable<UserPojo> {
    if (AuthenticationService.initialized) {
      return of(AuthenticationService._user);
    }
    return this.fetch();
  }

  private fetch() {

    const wrapper = new AsyncSubject();
    AuthenticationService.ongoingFetch = wrapper;

    this.userControllerService.getUserPojo()
      .subscribe({
      next: (u: any) => {
      const user = new User(u);
      wrapper.next(user);
      wrapper.complete();

      AuthenticationService.user.next(user);
      AuthenticationService.ongoingFetch = null;
    },
    error: (err: unknown) => {
      wrapper.error(err);
      AuthenticationService.user.next(undefined);
    }
      });

    return AuthenticationService.ongoingFetch;
  }

}
