import {Component, EventEmitter, OnInit, Output, Signal, signal, WritableSignal} from '@angular/core';
import {NameAndIdPojo, SectorControllerService} from "../../../../../../sdk/mynimc-api-sdk";
import {AlertType} from "../../../../shared/utils/utils";
import {HelperService} from "../../../../shared/utils/helper.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-generate-token-i',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    NgIf,
    NgForOf
  ],
  templateUrl: './generate-token-i.component.html',
  styleUrl: './generate-token-i.component.css'
})
export class GenerateTokenIComponent implements OnInit {
  @Output()
  emitFirstBackButtonClicked = new EventEmitter<any>();
  @Output()
  onFirstFormNextClicked = new EventEmitter<any>();
  @Output() alert = new EventEmitter<{ msg: string; type: AlertType }>();
  form: FormGroup;
  sectorList:WritableSignal<NameAndIdPojo[]> = signal([]);

  constructor(private sectorControllerService: SectorControllerService,
              private helperService: HelperService,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      keyword: [''],
      sectorCode: ['', [Validators.requiredTrue]]
    })

    this.form.get('keyword').valueChanges.subscribe(res => {
      if (res != null) {
        this.getSectorList(res);
      }
    })

    this.getSectorList();
  }

  getSectorList(keyword?: string) {
    this.sectorControllerService.searchSector({keyword: keyword, limit: 5}).subscribe({
      next: (x) => {
        this.sectorList.set(x);
      }, error: (err: unknown) => {
        this.alert.emit({msg: this.helperService.getError(err), type: AlertType.error});
      }
    })
  }

  selectNextClicked(){
    if(this.form.invalid){
      this.form.markAllAsTouched();
      this.alert.emit({msg: 'Kindly select a sector before proceeding', type: AlertType.error});
    }
    this.onFirstFormNextClicked.next(this.form.value.sectorCode)
  }
}
