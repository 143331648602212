import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {Observable} from "rxjs";
import {User} from "../../models/user/user.model";


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(private dbService: NgxIndexedDBService) { }

  addUser(person: any) {
    this.dbService.clear('mynimcuser')
    return this.dbService.add('mynimcuser', person);
  }

  getUser(id: number) {
    return this.dbService.getByKey('mynimcuser', id);
  }

  getAllUser(): Observable<User[]> {
    return this.dbService.getAll('mynimcuser');
  }

  updateUser(id: number, person: any) {
    return this.dbService.update('mynimcuser', { id, ...person });
  }

  deleteUser(id: number) {
    return this.dbService.delete('mynimcuser', id);
  }
}
