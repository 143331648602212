<div class="mt-5 mx-auto px-3 px-md-0 col-md-6 col-12 ">
  <div class="card rounded-4  border-0 " style="box-shadow: 0px 1px 15px 0px rgba(0, 103, 143, 0.15);">
    <div class="card py-2 border-0 rounded-4 text-center"
         style=" font-size: 18px; font-weight: bold;  background: rgba(1, 135, 58, 0.09);">
      Select a relying party
    </div>
    <div class="card-body">
      <form [formGroup]="form">
        <input formControlName="keyword" class="form-control rounded-3" placeholder="Enter keyword to search relying party"/>
      </form>

      <hr>

      <div *ngIf="relyingPartyList().length > 0; else noRelyingParty">
        <div *ngFor="let rely of relyingPartyList()">
          <div class=" d-flex mt-5">
            <input class="form-check-input mb-2" type="radio" value="" id="auth-check"
                   formControlName="customerAccountCode">
            <div
              style="border-radius: 5px; background: rgba(102, 102, 102, 0.20); display: flex;width: 27px;height: 28px;padding: 6px 5px 6px 6px;flex-direction: column;justify-content: center;align-items: center;flex-shrink: 0;">
              <span class="iconify fs-4" data-icon="ph:phone-light"></span>
            </div>
            <div class="ms-3">
              {{ rely?.name }}
            </div>
          </div>

          <hr>
        </div>
      </div>

      <ng-template #noRelyingParty>
        <p>No relying party found.</p>
      </ng-template>
      <div class="d-flex mt-5  justify-content-between">
        <div class="pointer" (click)="emitBackButtonClicked.emit()">
          <span class="iconify" data-icon="ph:arrow-left-fill" style="color: green;"></span>
          Previous
        </div>
        <div class="pointer" (click)="selectNextClicked()">
          Next
          <span class="iconify" data-icon="ph:arrow-right-fill" style="color: green;"></span>
        </div>
      </div>
    </div>
  </div>
</div>
