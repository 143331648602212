<div class="container">
  <div class="mt-5 ">
    <div class="d-flex align-items-center pointer" [routerLink]="'/dashboard'">
      <div class="p-1 px-2 me-2" style="background: rgba(1, 135, 58, 0.50); border-radius: 4px">
        <span class="iconify" data-icon="ep:back"></span>
      </div>
      <p class="mb-0 fs-14">Go back</p>
    </div>


    <div class=" mt-3" style="font-size: 45px;">
      View My Tokens
    </div>

    <div class=" mt-5 justify-content-center px-3 px-md-0  col-md-6 col-12 ">
      <div class="card rounded-4  border-0 " style="box-shadow: 0px 1px 15px 0px rgba(0, 103, 143, 0.15);">
        <div
          *ngIf="showAlertMessageTrigger"
          [ngClass]="{ anim: showAlertMessageTrigger }"
          [class.alert-success]="getMessageType() === AlertType.success"
          [class.alert-danger]="getMessageType() === AlertType.error"
          [class.alert-primary]="getMessageType() === AlertType.primary"
          [class.alert-info]="getMessageType() === AlertType.info"
          class="alert alert-danger my-5 text-center" role="alert"
        >
          {{ getAlertMessage() }}
        </div>
        <div class="card-body">
          <form [formGroup]="_form" (submit)="submit()">
            <div>
              <div class="mb-3 ">
                <input formControlName="keyword" class="form-control rounded-3"
                       placeholder="Enter keyword to search relying party"/>
              </div>
            </div>
            <div class="my-3  text-end ">
              <button class="btn btn-success rounded-3" type="submit" [disabled]="searchManager._working">
                <span class="iconify fs-3" data-icon="ic:baseline-search"></span>
                Search
              </button>
            </div>
            <hr>
          </form>

          <ng-container *ngIf="!searchManager._working; else stillLoading">
            <ng-container *ngIf="searchManager.list.length > 0; else NoDataToDisplay">
              <div class="card rounded-3 border-0 " *ngFor="let item of searchManager.list; let i = index"
                   style="box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);  background: #F7F7F7;">
                <div class="card-body">
                  <div class="d-flex">
                    <div
                      style="border-radius: 5px; background: rgba(102, 102, 102, 0.20); display: flex; width: 20px; height: 20px; padding: 5px 4px 4px 5px; justify-content: center; align-items: center; flex-shrink: 0;">
                      <span class="iconify fs-3" data-icon="ph:phone-light"></span>
                    </div>
                    <div class="ms-3" STYLE="font-weight: bold">
                      {{ item?.customerPojo?.sectorPojo?.name | uppercase }}
                    </div>
                  </div>
                  <div class="d-flex mt-2">
                    <div
                      style="border-radius: 5px; background: rgba(102, 102, 102, 0.20); display: flex; width: 20px; height: 20px; padding: 5px 4px 4px 5px; justify-content: center; align-items: center; flex-shrink: 0;">
                      <span class="iconify fs-3" data-icon="basil:arrow-right-outline"></span>
                    </div>
                    <div class="ms-3">
                      {{ item?.customerPojo?.name | uppercase }}
                    </div>
                  </div>
                  <div class="d-flex  mt-2">
                    <div class="d-flex">
                      <div
                        style="border-radius: 5px; background: rgba(102, 102, 102, 0.20); display: flex; width: 20px; height: 20px; padding: 5px 4px 4px 5px; justify-content: center; align-items: center; flex-shrink: 0;">
                        <span class="iconify fs-3" data-icon="pajamas:status"></span>
                      </div>
                      <div class="ms-3"
                           [ngStyle]="item?.status == 'ACTIVE' ? {'color': '#01903C'} : {'color': 'black'}">
                        {{ item?.status | uppercase }}
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between mt-2">
                    <div class="d-flex">
                      <div
                        style="border-radius: 5px; background: rgba(102, 102, 102, 0.20); display: flex; width: 20px; height: 20px; padding: 5px 4px 4px 5px; justify-content: center; align-items: center; flex-shrink: 0;">
                        <span class="iconify fs-3" data-icon="uiw:date"></span>
                      </div>
                      <div class=" ms-3">
                        <div>
                          <span style=" font-weight: bold;"> Generated On: </span>
                          <span>{{ item?.generatedNow | datetime }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mt-3 justify-content-between align-items-center">
                    <div class="d-flex mt-2">
                      <div style=" font-weight: bold; font-size: 24px; ">
                        {{ item?.token | uppercase }}
                      </div>
                    </div>
                    <div (click)="deleteCustomerValueConfirmation(item?.id, item?.token)" class="pointer"
                         style=" border-radius: 5px; background: rgba(200, 44, 32, 0.10); display: flex; width: 100px; height: 29px; padding: 10px; justify-content: center; align-items: center; gap: 10px; flex-shrink: 0; ">
                      Deactivate
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div
          class="row py-2 mx-2"
          [ngClass]="{ 'disabled-table': searchManager.working }">
          <div class="col-12 d-flex justify-content-end">
            <pagination
              [(ngModel)]="searchManager.page"
              [boundaryLinks]="true"
              [itemsPerPage]="searchManager.itemsPerPage"
              [maxSize]="maxPageSize"
              [rotate]="true"
              [totalItems]="searchManager.totalCount"
              firstText="&laquo;"
              lastText="&raquo;"
              nextText="&rsaquo;"
              previousText="&lsaquo;"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #stillLoading>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #NoDataToDisplay>
  <div>
    <div class="text-center" colspan="100%">NO DATA</div>
  </div>
</ng-template>
