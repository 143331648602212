<div class="mx-auto mt-5 justify-content-center px-3 px-md-0  col-md-6 col-12 " >
  <div class="card rounded-4  border-0 " style="box-shadow: 0px 1px 15px 0px rgba(0, 103, 143, 0.15);" >
    <div class="card py-2 border-0 rounded-4 text-center" style=" font-size: 18px; font-weight: bold;  background: rgba(1, 135, 58, 0.09);">
      Generated Token
    </div>

    <div class="card-body">
      <div class="card rounded-4 border-0" style="box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.08); background: rgba(0, 100, 241, 0.08); ">
        <div class="card-body">
          <div>
            <div style="font-weight: bold;">
              Sector
            </div>
            <div>
              {{ tokenizationPojo?.customerPojo?.sectorPojo?.name | titlecase}}
            </div>
          </div>
          <div class="mt-3">
            <div style="font-weight: bold;">
              Relying Party
            </div>
            <div>
              {{tokenizationPojo?.customerPojo?.name | titlecase}}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3 g-3">
        <div class="col-md-5">
          <div class="card rounded-4 border-0 h-100" style="background: rgba(0, 82, 204, 0.05) ; background: #F7F7F7; ">
            <div class="card-body py-5">
              <div class=" text-center d-flex flex-column align-items-center justify-content-center ">
                <div class="" style="font-weight: bold; font-size: 32px; text-align: center;">
                  {{tokenizationPojo?.token | uppercase}}
                </div>
                <div class="mt-3">
                  <div class="d-flex">
                    <div style="border-radius: 6px;background: rgba(1, 144, 60, 0.10); display: flex;width: 22px;height: 22px; padding: 5px 4px 4px 5px; justify-content: center; align-items: center; flex-shrink: 0;">
                      <span class="iconify fs-4" data-icon="solar:copy-outline" style="color: green;"></span>
                    </div>
                    <div class="ms-3 pointer" (click)="copyToClipBoard()">
                      Copy Token
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1 text-center align-items-md-center " style="font-weight: bold;"  >
          Or
        </div>
        <div class="col-md-6">
          <div class="card rounded-4 border-0 h-100" style="background: rgba(0, 82, 204, 0.05) ; background: #F7F7F7; ">
            <div class="card-body py-5">
              <div class=" text-center d-flex flex-column align-items-center justify-content-center ">
                <div class="" style="font-weight: bold; font-size: 32px; text-align: center;">
                  <qrcode
                    [qrdata]="tokenizationPojo?.token"
                    [allowEmptyString]="true"
                    [cssClass]="'center'"
                    [colorDark]="'#000000ff'"
                    [colorLight]="'#ffffffff'"
                    [elementType]="'canvas'"
                    [errorCorrectionLevel]="'M'"
                    [imageHeight]="45"
                    [imageWidth]="45"
                    [margin]="4"
                    [scale]="1"
                    [width]="200"
                  ></qrcode>
                </div>
                <div class="mt-3">
                  <div class="d-flex">
                    <div style="border-radius: 6px;background: rgba(1, 144, 60, 0.10); display: flex;width: 22px;height: 22px; padding: 5px 4px 4px 5px; justify-content: center; align-items: center; flex-shrink: 0;">
                      <span class="iconify fs-4" data-icon="mage:scan" style="color: green;"></span>
                    </div>
                    <div class="ms-3">
                      Scan to View Token
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 my-2 text-center " *ngIf="tokenizationPojo">
        <button class="btn btn-success rounded-3 pointer" [routerLink]="'/dashboard'">
          <span class="iconify fs-3" data-icon="iconamoon:check-light"></span>
          Done
        </button>
      </div>
    </div>
  </div>
</div>
