import {Component, OnInit} from '@angular/core';
import {SearchHandler} from "../../../shared/search/search-handler";
import {SearchFilterSource} from "../../../shared/models/search/search-filter-source";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HelperService} from "../../../shared/utils/helper.service";
import {SearchManager} from "../../../shared/models/search/search-manager";
import {
  GeneratedTokenControllerService, TokenizationPojo,
  GeneratedTokenSearchFilter, QueryResultsTokenizationPojo
} from "../../../../../sdk/mynimc-api-sdk";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {Observable} from "rxjs";
import {AlertType} from "../../../shared/utils/utils";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {NgClass, NgForOf, NgIf, NgStyle, UpperCasePipe} from "@angular/common";
import {SharedModule} from "../../../shared/shared.module";
import {BsModalService} from "ngx-bootstrap/modal";
import {
  ConfirmationDialogComponent
} from "../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-my-token-history',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PaginationModule,
    FormsModule,
    NgIf,
    NgClass,
    NgForOf,
    UpperCasePipe,
    NgStyle,
    SharedModule,
    RouterLink
  ],
  templateUrl: './my-token-history.component.html',
  styleUrl: './my-token-history.component.css'
})
export class MyTokenHistoryComponent implements OnInit, SearchHandler<TokenizationPojo, GeneratedTokenSearchFilter>, SearchFilterSource<GeneratedTokenSearchFilter>{
  _form: FormGroup;
  submitting = false;
  maxPageSize = 5;
  searchManager!: SearchManager<TokenizationPojo, GeneratedTokenSearchFilter>;


  constructor(private generatedTokenControllerService: GeneratedTokenControllerService,
              public helperService: HelperService,
              private fb: FormBuilder,
              private modalService: BsModalService) {
    this.searchManager = new SearchManager<TokenizationPojo, GeneratedTokenSearchFilter>(this, this);
  }

  ngOnInit(): void {
    this._form = this.fb.group({
      keyword: ['']
    });

    this.searchManager.itemsPerPage = 5;
    this.submit()
  }

  submit(): void {
    if (!this._form.valid) {
      return;
    }
    this.searchManager.reloadAndShowFirstPage();
  }


  getPersistentKey(): string {
    return MyTokenHistoryComponent.name;
  }


  getFilter(): GeneratedTokenSearchFilter {
    const form = this._form.getRawValue();

    return Object.assign(form);
  }

  getSearchDescriptor(e: GeneratedTokenSearchFilter): NameValuePair[] {
    const descriptions: NameValuePair[] = [];

    Object.keys(e).forEach((key) => {
      if (!(e as any)[key]) {
        return;
      }
      const val = (e as any)[key];
      descriptions.push(new NameValuePair(val, key));
    });
    return descriptions;
  }


  search(page: number | undefined, filter?: GeneratedTokenSearchFilter): Observable<QueryResultsTokenizationPojo> {
    const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;

    filter.offset = offset;
    filter.limit = this.searchManager.itemsPerPage

    return this.generatedTokenControllerService.searchTokenHistory({filter});
  }

  deleteCustomerValueConfirmation(id, token: string): void {
    const bsModal = this.modalService.show(ConfirmationDialogComponent, {
      initialState: {
        infoMessage: 'Deactivate Token ' + token.toString() + " ?"
      },
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered',
      keyboard: false
    });
    bsModal.content['onConfirmClicked'].subscribe(() => {
      this.deactivateToken(id);
      bsModal.hide();
    })
  }

  private deactivateToken(id) {
    this.generatedTokenControllerService.deactivateToken({id: id}).subscribe({
      next: (v) => {
        this.showAlertMessage('Token Deactivated Successfully.', AlertType.success);
        this.submit();
      }, error: (err: unknown) =>{
        this.showAlertMessage(this.helperService.getError(err), AlertType.error);
      }
    })
  }

  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  }
  showAlertMessageTrigger = false;

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  protected readonly AlertType = AlertType;
}
