import { Component } from '@angular/core';
import {ActivationStart, Router, RouterOutlet} from '@angular/router';
import {SplashScreenComponent} from "./modules/extranet/splash-screen/splash-screen.component";
import {SplashAnimationType} from "./models/enum/splash-animation-type-enum";
import {Utils} from "./shared/utils/utils";
import {SiteFooterComponent} from "./modules/extranet/site-footer/site-footer.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SplashScreenComponent, SiteFooterComponent, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'my-nimc-frontend';
  defaultAnimationType = Utils.enumValues(SplashAnimationType)[Math.floor(Math.random() * Utils.enumValues(SplashAnimationType).length) + 1];
  showFooter: string;

  constructor(private router: Router) {

    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart) {
        if (data) {
          this.showFooter = data?.snapshot?.data['showFooter'];
        }
      }
    });
  }
}
