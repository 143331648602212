import {Routes} from '@angular/router';
import {StartPageComponent} from "./modules/extranet/start-page/start-page.component";
import {VerifyCrcNinComponent} from "./modules/extranet/verify-crc-nin/verify-crc-nin.component";
import {DashboardComponent} from "./modules/intranet/dashboard/dashboard.component";
import {GenerateTokenComponent} from "./modules/intranet/generate-token/generate-token.component";
import {MyTokenHistoryComponent} from "./modules/intranet/my-token-history/my-token-history.component";
import {ForbiddenComponent} from "./modules/extranet/forbidden/forbidden.component";

export const routes: Routes = [
  {
    path: '', component: StartPageComponent, pathMatch: 'full',
    data: {
      showFooter: true
    }
  },
  {
    path: 'forbidden', component: ForbiddenComponent, pathMatch: 'full',
    data: {
      showFooter: false
    }
  },
  {
    path: 'verify-crc', component: VerifyCrcNinComponent, pathMatch: 'full',
    data: {
      showFooter: false
    }
  },
  {
    path: 'dashboard', component: DashboardComponent, pathMatch: 'full',
    data: {
      showFooter: false
    }
  },
  {
    path: 'generate-token', component: GenerateTokenComponent, pathMatch: 'full',
    data: {
      showFooter: false
    }
  },
  {
    path: 'view-token-history', component: MyTokenHistoryComponent, pathMatch: 'full',
    data: {
      showFooter: false
    }
  }
];
